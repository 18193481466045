import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/auth/guards/auth.guard';
import { RouteHandlerComponent } from '@app/core/route-handler/route-handler.component';
import { environment } from '@app/environment';

const routes: Routes = [
  {
    path: '',
    component: RouteHandlerComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home-page/home-page.module').then((m) => m.HomePageModule),
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/search-page/search-page.module').then((m) => m.SearchPageModule),
      },
      {
        path: 'redirect',
        loadChildren: () => import('./components/redirect/redirect.module').then((m) => m.RedirectModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile-page/profile-page.module').then((m) => m.ProfilePageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'selections',
        loadChildren: () =>
          import('./pages/selections-page/selections-page.module').then((m) => m.SelectionsPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'tracking',
        loadChildren: () => import('./pages/tracking-page/tracking-page.module').then((m) => m.TrackingPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'groups',
        loadChildren: () => import('./pages/groups-page/groups-page.module').then((m) => m.GroupsPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'my-articles',
        loadChildren: () =>
          import('./pages/my-articles-page/my-articles-page.module').then((m) => m.MyArticlesPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'payment',
        loadChildren: () => import('./pages/payment-pages/payment-pages.module').then((m) => m.PaymentPagesModule),
      },
    ],
  },
];

if (environment.showDevPages) {
  routes.splice(0, 0, {
    path: 'dev',
    loadChildren: () => import('./pages/dev-pages/dev-pages.module').then((m) => m.DevPagesModule),
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
