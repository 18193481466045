import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthProfileStateActions } from '@app/core/auth/state/auth-profile.state.actions';
import { Store } from '@ngxs/store';
import { catchError, concatMap, map, Observable, of, tap, throwError, withLatestFrom } from 'rxjs';

import { AuthProfileState } from '../state/auth-profile.state';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  public intercept(originalReq: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token$ = this.store.select(AuthProfileState.token);

    return of(true).pipe(
      withLatestFrom(token$),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([_, token]: [boolean, string | null]) => this.addToken(originalReq, token)),
      concatMap((req: HttpRequest<unknown>) => next.handle(req)),
    );
  }

  protected addToken(req: HttpRequest<unknown>, token: string | null): HttpRequest<unknown> {
    const alreadyHasToken = !!req.headers.get('authorization');

    return !token || alreadyHasToken ? req : req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

}
