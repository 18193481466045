import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgxsModule, Store } from '@ngxs/store';

import { SettingsState } from './settings.state';
import { SettingsStateActions } from './settings.state.actions';

@NgModule({
  imports: [NgxsModule.forFeature([SettingsState])],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store) => () => store.dispatch(new SettingsStateActions.Load()),
      deps: [Store],
      multi: true,
    },
  ],
})
export class SettingsStoreModule {}
