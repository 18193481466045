import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function repeatPasswordGroupValidator(
  passwordControlName: string,
  passwordRepeatControlName: string,
): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const passwordControl = group.get(passwordControlName);
    const passwordRepeatControl = group.get(passwordRepeatControlName);

    if (!passwordControl) {
      console.warn(`passwordControl is not found`);
    }

    if (!passwordRepeatControl) {
      console.warn(`passwordRepeatControl is not found`);
    }

    return !passwordControl || !passwordRepeatControl || passwordControl.value === passwordRepeatControl.value
      ? null
      : { repeatPasswordError: true };
  };
}
