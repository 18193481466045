import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environment';
import { joinUrl } from '@app/shared/utils/url.utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiBaseUrlInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.changeRequest(req));
  }

  private changeRequest(req: HttpRequest<unknown>): HttpRequest<unknown> {
    const url =
      req.url.startsWith('http') || req.url.startsWith('/assets') ? req.url : joinUrl(environment.baseUrl, req.url);

    return req.clone({
      url,
      withCredentials: true,
    });
  }
}
