import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@app/environment';
import { joinUrl } from '@app/shared/utils/url.utils';

@Pipe({
  name: 'storageUrl',
})
export class StorageUrlPipe implements PipeTransform {
  transform(value: string | null | undefined): string | null | undefined {
    return value ? joinUrl(environment.baseUrl, value) : value;
  }
}
