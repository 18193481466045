import { Injectable, NgZone } from '@angular/core';
import { asyncScheduler, BehaviorSubject, map, Observable } from 'rxjs';
import { finalize, observeOn } from 'rxjs/operators';

type LoadingContext = object;
type LoaderId = string | number; // expected enum values
const DEFAULT_LOADER_ID: LoaderId = '_DEFAULT';

/**
 * Используется для управления состоянием загрузки копонтентов и сервисов.
 *
 * Синхронное управление состоянием:
 *
 * ```js
 * loaderService.startLoading(this);
 * loaderService.isLoading(this); // true
 *
 * loaderService.endLoading(this);
 * loaderService.isLoading(this); // false
 * ```
 *
 * Асинхронное управление состоянием:
 *
 * ```js
 * loaderService.doLoading(source$, this).subscribe();
 *
 * loaderService.isLoading$(this)
 *    .pipe(tap(isLoading => console.log(isLoading))) // true/false
 *    .subscribe();
 * ```
 *
 * Управление состоянием нескольких лоадеров в одном контексте:
 *
 * ```js
 * loaderService.isLoading(this); // false
 * loaderService.isLoading(this, 'LOADER_1'); // false
 * loaderService.isLoading(this, 'LOADER_2'); // false
 *
 * loaderService.startLoading(this, 'LOADER_1');
 * loaderService.startLoading(this, 'LOADER_2');
 *
 * loaderService.isLoading(this); // true
 * loaderService.isLoading(this, 'LOADER_1'); // true
 * loaderService.isLoading(this, 'LOADER_2'); // true
 *
 * loaderService.endLoading(this, 'LOADER_1');
 *
 * loaderService.isLoading(this); // true
 * loaderService.isLoading(this, 'LOADER_1'); // false
 * loaderService.isLoading(this, 'LOADER_2'); // true
 *
 * loaderService.endLoading(this, 'LOADER_2');
 *
 * loaderService.isLoading(this); // false
 * loaderService.isLoading(this, 'LOADER_1'); // false
 * loaderService.isLoading(this, 'LOADER_2'); // false
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  /** @ignore */
  protected loadingStates$ = new BehaviorSubject(new WeakMap<LoadingContext, Map<LoaderId, boolean>>());

  /** @ignore */
  constructor(protected zoneRef: NgZone) {}

  /**
   * Обёртка над Observable, выполняющим загрузку.
   *
   * Пример использования:
   *
   * ```js
   * loaderService.doLoading(apiService.getUsers(), this, 'USER_LIST');
   * ```
   *
   * @param source$ Observable, выполняющий загрузку.
   * @param context Любой объект определяющий контекст загрузки, чаще всего экземпляр компонента или сервиса.
   * @param loaderId Необязательный параметр для управления несколькими лоадерами внутри одного контекста.
   */
  public doLoading<V>(source$: Observable<V>, context: LoadingContext, loaderId?: LoaderId): Observable<V> {
    this.startLoading(context, loaderId);

    return source$.pipe(
      observeOn(asyncScheduler),
      finalize(() => this.endLoading(context, loaderId)),
    );
  }

  /**
   * Получение текущего состояния заданного лоадера в заданном контексте.
   *
   * Если loaderId не задан, функция вернёт true, когда хотя бы один лоадер в контексте активен.
   *
   * Пример использования:
   * ```js
   * loaderService.isLoading(this);
   * loaderService.isLoading(this, 'USER_LIST');
   * ```
   *
   * @see isLoading$() для асинхронного получения состояния лоадера.
   *
   * @param context Любой объект определяющий контекст загрузки, чаще всего экземпляр компонента или сервиса.
   * @param loaderId Необязательный параметр для управления несколькими лоадерами внутри одного контекста.
   */
  public isLoading(context: LoadingContext, loaderId?: LoaderId): boolean {
    const loaderStates = this.loadingStates$.value.get(context);

    if (!loaderStates) {
      return false;
    } else {
      if (loaderId !== undefined) {
        return loaderStates.get(this.getLoaderId(loaderId)) ?? false;
      } else {
        return [...loaderStates.values()].filter((state) => state).length > 0;
      }
    }
  }

  /**
   * Получение Observable, определяющего состояние заданного лоадера в заданном контексте.
   *
   * Если loaderId не задан, Observable будет возвращать true, когда хотя бы один лоадер в контексте активен.
   *
   * Пример использования:
   * ```js
   * loaderService.isLoading$(this).subscribe();
   * loaderService.isLoading$(this, 'USER_LIST').subscribe();
   * ```
   *
   * @see isLoading() для асинхронного получения состояния лоадера.
   *
   * @param context Любой объект определяющий контекст загрузки, чаще всего экземпляр компонента или сервиса.
   * @param loaderId Необязательный параметр для управления несколькими лоадерами внутри одного контекста.
   */
  public isLoading$(context: LoadingContext, loaderId?: LoaderId): Observable<boolean> {
    const coalescedLoaderId = this.getLoaderId(loaderId);

    if (!this.hasLoadingStates(context, coalescedLoaderId) && loaderId) {
      this.setLoadingState(context, false, loaderId);
    }

    return this.loadingStates$.pipe(
      map((loadingStates) =>
        loaderId
          ? loadingStates.get(context)?.get(this.getLoaderId(loaderId)) || false
          : [...(loadingStates.get(context)?.values() || [])].filter(Boolean).length > 0,
      ),
    );
  }

  /**
   * Переводит заданный лоадер в заданном контексте в активное состояние.
   *
   * Методы startLoading, endLoading нужны для обеспечения гибкости в сложных сценариях.
   *
   * @see doLoading() для обёртки Observable запросов.
   *
   * @param context Любой объект определяющий контекст загрузки, чаще всего экземпляр компонента или сервиса.
   * @param loaderId Необязательный параметр для управления несколькими лоадерами внутри одного контекста.
   */
  public startLoading(context: LoadingContext, loaderId?: LoaderId): void {
    this.setLoadingState(context, true, this.getLoaderId(loaderId));
  }

  /**
   * Переводит заданный лоадер в заданном контексте в неактивное состояние.
   *
   * Методы startLoading, endLoading нужны для обеспечения гибкости в сложных сценариях.
   *
   * @see doLoading() для обёртки Observable запросов.
   *
   * @param context Любой объект определяющий контекст загрузки, чаще всего экземпляр компонента или сервиса.
   * @param loaderId Необязательный параметр для управления несколькими лоадерами внутри одного контекста.
   */
  public endLoading(context: LoadingContext, loaderId?: LoaderId): void {
    this.setLoadingState(context, false, this.getLoaderId(loaderId));
  }

  /**
   * Переводит все лоадеры приложения в неактивное состояние.
   *
   * Используется в middleware при возникновении ошибок
   */
  public clearLoadings(): void {
    this.loadingStates$.next(new WeakMap<LoadingContext, Map<LoaderId, boolean>>());
  }

  /** @ignore */
  protected setLoadingState(context: LoadingContext, state: boolean, loaderId: LoaderId): void {
    const loadingStates = this.loadingStates$.value;

    if (this.hasLoadingStates(context, loaderId)) {
      loadingStates.get(context)!.set(loaderId, state);
    } else {
      if (this.hasContextLoadingState(context)) {
        loadingStates.get(context)!.set(loaderId, state);
      } else {
        loadingStates.set(context, new Map<LoaderId, boolean>([[loaderId, state]]));
      }
    }

    this.loadingStates$.next(loadingStates);
  }

  /** @ignore */
  protected hasLoadingStates(context: LoadingContext, loaderId: LoaderId): boolean {
    return this.hasContextLoadingState(context) && this.hasLoaderLoadingState(context, loaderId);
  }

  /** @ignore */
  protected hasContextLoadingState(context: LoadingContext): boolean {
    return this.loadingStates$.value.has(context);
  }

  /** @ignore */
  protected hasLoaderLoadingState(context: LoadingContext, loaderId: LoaderId): boolean {
    return this.loadingStates$.value.get(context)?.has(loaderId) || false;
  }

  /** @ignore */
  protected getLoaderId(loaderId?: LoaderId): LoaderId {
    return loaderId ?? DEFAULT_LOADER_ID;
  }
}
