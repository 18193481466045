import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthProfileStateActions } from '@app/core/auth/state/auth-profile.state.actions';
import { Store } from '@ngxs/store';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedErrorInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  public intercept(originalReq: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(originalReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === <number>HttpStatusCode.Unauthorized) {
            this.store.dispatch(new AuthProfileStateActions.Clear());
          }
        }

        return throwError(error);
      }),
    );
  }
}
