import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-input-error, [appInputError]',
  exportAs: 'appInputError',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'input-error',
  },
})
export class InputErrorComponent {}
