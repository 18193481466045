import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasePipesModule } from '@app/shared/pipes/base-pipes/base-pipes.module';
import { InputSharedModule } from '@app/shared/ui/forms/input-shared/input-shared.module';

import { InputTextComponent } from './input-text.component';

@NgModule({
  declarations: [InputTextComponent],
  imports: [CommonModule, BasePipesModule, FormsModule, InputSharedModule, ReactiveFormsModule, TextFieldModule],
  exports: [InputTextComponent, InputSharedModule],
})
export class InputTextModule {}
