import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SettingsResponseDto } from './dtos/settings.response.dto';

@Injectable({ providedIn: 'root' })
export class SettingsApiService {
  constructor(private readonly http: HttpClient) {}

  get(): Observable<SettingsResponseDto> {
    return this.http.get<SettingsResponseDto>('/api/v1/settings');
  }
}
