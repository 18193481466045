import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { StoragePipesModule } from '@app/features/selections/storage/storage-pipes/storage-pipes.module';
import { UserAvatarModule } from '@app/features/user/user-avatar/user-avatar.module';
import { SoonModalModule } from '@app/modals/soon-modal/soon-modal.module';
import { LetModule } from '@app/shared/directives/let';
import { BasePipesModule } from '@app/shared/pipes/base-pipes/base-pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';

import { SocialModule } from '../social/social.module';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatMenuModule,
    SocialModule,
    BasePipesModule,
    LetModule,
    NgOptimizedImage,
    UserAvatarModule,
    SoonModalModule,
    StoragePipesModule,
  ],
})
export class HeaderModule {}
