export function repeat<T>(count: number, fillValue: T): T[];
export function repeat<T>(count: number, fillFn: (index: number) => T): T[];
export function repeat<T>(count: number, fillFnOrValue: T | ((index: number) => T)): T[] {
  return new Array(count).fill(null).map((_, index) => {
    if (typeof fillFnOrValue === 'function') {
      return (fillFnOrValue as (index: number) => T)(index);
    } else {
      return fillFnOrValue;
    }
  });
}
