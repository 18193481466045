import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { BasePipesModule } from '@app/shared/pipes/base-pipes/base-pipes.module';

import { TariffRestrictionInterceptor } from './tariff-restriction.interceptor';
import { TariffRestrictionModalComponent } from './tariff-restriction-modal/tariff-restriction-modal.component';

@NgModule({
  declarations: [TariffRestrictionModalComponent],
  imports: [CommonModule, MatDialogModule, MatIconModule, BasePipesModule, RouterLink],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: TariffRestrictionInterceptor,
      multi: true,
    },
  ],
})
export class TariffRestrictionModule {}
