<div class="modal-container" [appLoader]="this | isLoading | async | default: false">
  <ng-container *ngIf="(registrationFinished$ | async) === false; else finished">
    <div class="header">Регистрация</div>
    <button class="modal-close" mat-dialog-close>
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <form class="body" [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
      <div class="controls">
        <app-input-text type="text" placeholder="Введите email" formControlName="email">
          <app-input-label>Email <span class="required">*</span></app-input-label>
        </app-input-text>

        <app-input-text type="text" placeholder="Введите имя" formControlName="username">
          <app-input-label>Имя пользователя <span class="required">*</span></app-input-label>
        </app-input-text>

        <app-input-text
          *appLet="showPassword$ | async as show"
          [type]="show ? 'text' : 'password'"
          placeholder="Введите пароль"
          formControlName="password"
        >
          <app-input-label>Пароль <span class="required">*</span></app-input-label>
          <button appInputSuffix type="button" tabindex="-1" (click)="toggleShowPassword()">
            <mat-icon [svgIcon]="show ? 'eye' : 'eye-closed'"></mat-icon>
          </button>
          <app-input-error
            *ngIf="
              signUpForm.get('password')?.touched && signUpForm.get('password')?.getError('minlength') as minLengthError
            "
          >
            Пароль должен содержать минимум {{ minLengthError.requiredLength }} символов
          </app-input-error>
        </app-input-text>

        <app-input-text
          *appLet="showPasswordRepeat$ | async as show"
          [type]="show ? 'text' : 'password'"
          placeholder="Введите пароль"
          formControlName="passwordRepeat"
        >
          <app-input-label>Повторите пароль <span class="required">*</span></app-input-label>
          <button appInputSuffix type="button" tabindex="-1" (click)="toggleShowPasswordRepeat()">
            <mat-icon [svgIcon]="show ? 'eye' : 'eye-closed'"></mat-icon>
          </button>
          <app-input-error
            *ngIf="signUpForm.get('passwordRepeat')?.touched && signUpForm.hasError('repeatPasswordError')"
          >
            Пароли должны совпадать
          </app-input-error>
        </app-input-text>
      </div>

      <div class="actions">
        <button type="submit" class="button btn-primary" [disabled]="signUpForm.invalid">Зарегистрироваться</button>
        <button type="button" class="button button--link" (click)="switchToSignIn()">Войти</button>
      </div>

      <!--<div class="alternative">
        <div class="alternative__label">Регистрация через</div>
        <div>
          <button type="button" class="btn btn-outline-primary me-2"><img src="/assets/images/ya.png" /> Яндекс</button>
          <button type="button" class="btn btn-outline-primary me-2"><img src="/assets/images/goo.png" /> Google</button>
        </div>
      </div>-->
    </form>
  </ng-container>
  <ng-template #finished>
    <div class="registration-complete">
      <div class="registration-complete__icon">
        <mat-icon svgIcon="check"></mat-icon>
      </div>
      <div class="registration-complete__title">
        Регистрация успешно <br />
        завершена
      </div>
      <div class="registration-complete__actions">
        <button class="button" type="button" mat-dialog-close="">Закрыть</button>
      </div>
    </div>
  </ng-template>
</div>
