import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  templateUrl: './updater.component.html',
  styleUrls: ['./updater.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdaterComponent {
  public update(): void {
    document.location.reload();
  }
}
