import { Directive } from '@angular/core';

@Directive({
  selector: 'app-input-prefix, [appInputPrefix]',
  exportAs: 'appInputPrefix',
  host: {
    class: 'input-prefix',
    tabindex: '-1',
  },
})
export class InputPrefixDirective {}
