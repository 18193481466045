import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from '@app/core/loader/loader.service';
import { LoaderHttpErrorInterceptor } from '@app/core/loader/loader-http-error.interceptor';

import { IsLoadingPipe } from './is-loading.pipe';
import { LoaderDirective } from './loader.directive';
import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';

@NgModule({
  declarations: [LoaderSpinnerComponent, LoaderDirective, IsLoadingPipe],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [LoaderDirective, IsLoadingPipe, LoaderSpinnerComponent],
  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderHttpErrorInterceptor,
      multi: true,
    },
  ],
})
export class LoaderModule {}
