import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserApiService } from '@app/core/api/user/user-api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, Observable, of, tap } from 'rxjs';

import { ProfileModel } from '../models/profile.model';
import { AuthProfileStateActions } from './auth-profile.state.actions';

interface AuthProfileStateModel {
  profile: ProfileModel | null;
  initialized: boolean;
  token: string | null;
}

const EMPTY_STATE: AuthProfileStateModel = {
  profile: null,
  initialized: false,
  token: null,
};

@State<AuthProfileStateModel>({
  name: 'profile',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class AuthProfileState {
  @Selector()
  public static initialized(state: AuthProfileStateModel): boolean {
    return state.initialized;
  }

  @Selector()
  public static profile(state: AuthProfileStateModel): ProfileModel | null {
    return state.profile;
  }

  @Selector()
  public static token(state: AuthProfileStateModel): string | null {
    return state.token;
  }

  @Selector([AuthProfileState.profile])
  public static avatar(state: AuthProfileStateModel, profile: ProfileModel | null): string | null {
    return profile?.avatar || null;
  }

  @Selector([AuthProfileState.profile])
  public static profileId(state: AuthProfileStateModel, profile: ProfileModel | null): number | null {
    return profile?.id || null;
  }

  constructor(
    private readonly userApi: UserApiService,
    private router: Router,
  ) {}

  @Action(AuthProfileStateActions.Load, { cancelUncompleted: true })
  public loadProfile({ patchState }: StateContext<AuthProfileStateModel>): Observable<unknown> {
    return this.userApi.me().pipe(
      catchError(() => of(null)),
      tap((profile) =>
        patchState({
          initialized: true,
          profile: profile?.data,
        }),
      ),
    );
  }

  @Action(AuthProfileStateActions.LoadByToken, { cancelUncompleted: true })
  public loadByTokenProfile(
    { patchState, dispatch }: StateContext<AuthProfileStateModel>,
    { token }: AuthProfileStateActions.LoadByToken,
  ): void {
    patchState({ token });
    dispatch(AuthProfileStateActions.Load);
    this.router.navigate(['/']);
  }

  @Action(AuthProfileStateActions.Clear)
  public clearProfile({ patchState }: StateContext<AuthProfileStateModel>): void {
    patchState({
      profile: null,
      token: null,
    });
  }
}
