<div class="modal-container" [appLoader]="this | isLoading | async | default: false">
  <div class="header">Восстановление пароля</div>
  <button class="modal-close" mat-dialog-close>
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <div class="body">
    <form
      *ngIf="(emailSent$ | async) === false; else emailSentMessage"
      [formGroup]="recoveryForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="description">
        <p>
          Введите адрес электронной почты. <br />
          Мы отправим Вам письмо с ссылкой для изменения пароля.
        </p>
      </div>
      <div class="controls">
        <app-input-text formControlName="email">
          <app-input-label>Email</app-input-label>
        </app-input-text>
      </div>
      <div class="actions">
        <button type="submit" [disabled]="!recoveryForm.valid" class="button">Отправить</button>
      </div>
    </form>
    <ng-template #emailSentMessage>
      <div>
        <div class="description">
          <p>
            На почту <strong>{{ recoveryForm.get('email')?.value }}</strong> было отправлено письмо для восстановления
            пароля.
          </p>
          <p>Перейдите по ссылке из письма, чтобы сбросить предыдущий пароль</p>
        </div>
        <div class="actions">
          <button type="submit" class="button" mat-dialog-close="">Закрыть</button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
