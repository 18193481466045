import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tariff-restriction-modal',
  templateUrl: './tariff-restriction-modal.component.html',
  styleUrls: ['./tariff-restriction-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TariffRestrictionModalComponent {
  public static open(dialog: MatDialog): MatDialogRef<TariffRestrictionModalComponent> {
    return dialog.open(TariffRestrictionModalComponent);
  }
}
