import { DEFAULT_DIALOG_CONFIG, Dialog as CdkDialog, DIALOG_SCROLL_STRATEGY, DialogConfig } from '@angular/cdk/dialog';
import { Inject, Injectable, Injector, Optional, SkipSelf } from '@angular/core';

import { DialogOverlayContainerService, DialogOverlayService } from './dialog-overlay';

@Injectable()
export class DialogService extends CdkDialog {
  constructor(
    _overlay: DialogOverlayService,
    _injector: Injector,
    @Optional() @Inject(DEFAULT_DIALOG_CONFIG) _defaultOptions: DialogConfig,
    @Optional() @SkipSelf() _parentDialog: CdkDialog,
    _overlayContainer: DialogOverlayContainerService,
    @Inject(DIALOG_SCROLL_STRATEGY) scrollStrategy: never,
  ) {
    super(_overlay, _injector, _defaultOptions, _parentDialog, _overlayContainer, scrollStrategy);
  }
}
