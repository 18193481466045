import { Directive } from '@angular/core';

@Directive({
  selector: 'app-input-suffix, [appInputSuffix]',
  exportAs: 'appInputSuffix',
  host: {
    class: 'input-suffix',
    tabindex: '-1',
  },
})
export class InputSuffixDirective {}
