<ng-container *ngIf="profileInitialized$ | async; else preloader">
  <app-header
    [isAuthorized]="authenticated$ | async | default: false"
    (onSignIn)="openSignInModal()"
    (onLogOut)="logout()"
    (onSignUp)="openSignUpModal()"
  >
  </app-header>
  <div
    [class.page-router-container]="(route$ | async) !== '/' && !(route$ | async)?.startsWith('/search')"
    [class.router-container]="(route$ | async) === '/' || (route$ | async)?.startsWith('/search')"
  >
    <router-outlet></router-outlet>
  </div>
</ng-container>

<ng-template #preloader>
  <div class="preloader">
    <app-loader-spinner></app-loader-spinner>
  </div>
</ng-template>
