export function wordDeclension(
  count: number | null | undefined,
  nounOne: string,
  nounTwo: string,
  nounFive: string,
): string | null {
  if (typeof count === 'undefined' || count === null) {
    return null;
  }

  let absCount = Math.abs(count);
  absCount %= 100;

  if (absCount >= 5 && absCount <= 20) {
    return nounFive;
  }

  absCount %= 10;

  if (absCount === 1) {
    return nounOne;
  }

  if (absCount >= 2 && absCount <= 4) {
    return nounTwo;
  }

  return nounFive;
}
