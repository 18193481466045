import { Component, Self } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthRegisterRequestDto } from '@app/core/api/auth/dtos/auth-register.request.dto';
import { AuthRegisterResponseDto } from '@app/core/api/auth/dtos/auth-register.response.dto';
import { AuthService } from '@app/core/auth/services/auth.service';
import { LoaderService } from '@app/core/loader/loader.service';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';
import { snackBarApiErrorFallback } from '@app/core/snack-bar/snack-bar-rxjs.utils';
import { SignInModalComponent } from '@app/modals/sign-in-modal/sign-in-modal.component';
import { DestroyService } from '@app/shared/utils/destroy.service';
import { repeatPasswordGroupValidator } from '@app/shared/validators/repeat-password-group.validator';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';

export interface SignUpModalData {}

@Component({
  selector: 'app-sign-up-modal',
  templateUrl: './sign-up-modal.component.html',
  styleUrls: ['./sign-up-modal.component.scss'],
  providers: [DestroyService],
})
export class SignUpModalComponent {
  signUpForm = new FormGroup(
    {
      email: new FormControl('', [Validators.required, Validators.email]),
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      passwordRepeat: new FormControl('', [Validators.required]),
    },
    {
      validators: [repeatPasswordGroupValidator('password', 'passwordRepeat')],
    },
  );

  showPassword$ = new BehaviorSubject<boolean>(false);

  showPasswordRepeat$ = new BehaviorSubject<boolean>(false);

  registrationFinished$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<SignUpModalComponent, void>,
    private readonly snackBar: SnackBarService,
    private readonly loaderService: LoaderService,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  static open(dialog: MatDialog, data: SignUpModalData): MatDialogRef<SignUpModalComponent, boolean> {
    return dialog.open(SignUpModalComponent, {
      data,
      width: '480px',
    });
  }

  onSubmit(): void {
    if (this.signUpForm.valid) {
      const value = this.signUpForm.value;

      this.wrapSignUpRequest({
        email: value.email as string,
        username: value.username as string,
        password: value.password as string,
        password_confirmation: value.passwordRepeat as string,
      })
        .pipe(tap(() => this.registrationFinished$.next(true)))
        .subscribe();
    } else {
      this.signUpForm.markAllAsTouched();
    }
  }

  toggleShowPassword(): void {
    this.showPassword$.next(!this.showPassword$.value);
  }

  toggleShowPasswordRepeat(): void {
    this.showPasswordRepeat$.next(!this.showPasswordRepeat$.value);
  }

  switchToSignIn(): void {
    SignInModalComponent.open(this.dialog, {});
    this.dialogRef.close();
  }

  protected wrapSignUpRequest(params: AuthRegisterRequestDto): Observable<AuthRegisterResponseDto> {
    const source$ = this.authService
      .signUp(params)
      .pipe(take(1), snackBarApiErrorFallback(this.snackBar, 'Ошибка при регистрации'));

    return this.loaderService.doLoading(source$, this, 'REGISTER');
  }
}
