import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '@app/core/loader/loader.service';
import { catchError, Observable, throwError } from 'rxjs';

/**
 * Интерсептор, который при ошибке в HTTP запросе, убирает все лоадеры
 */
@Injectable()
export class LoaderHttpErrorInterceptor implements HttpInterceptor {
  constructor(private readonly loaderService: LoaderService) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error) => {
        this.loaderService.clearLoadings();
        return throwError(error);
      }),
    );
  }
}
