<header class="header" *appLet="profile$ | async as profile">
  <div class="header__container container">
    <a [routerLink]="['/']" class="header__logo">
      <img src="/assets/images/logo.svg" alt="" />
    </a>
    <div *ngIf="isAuthorized" class="header__menu d-none d-lg-flex">
      <div class="header__menu__item" [routerLink]="'/search'">Поиск</div>
      <div class="header__menu__item" [routerLink]="'/selections'">Подборки литературы</div>
      <div class="header__menu__item" [routerLink]="'/tracking'">Отслеживание статей</div>
      <div class="header__menu__item" [routerLink]="'/groups'">Группы</div>
      <div class="header__menu__item" [routerLink]="'/my-articles'">Мои статьи</div>
    </div>
    <div class="header__buttons">
      <ng-container *ngIf="isAuthorized">
        <div [matMenuTriggerFor]="menu" class="header__user d-none d-lg-flex">
          <div
            appUserAvatar
            [src]="profile?.avatar | storageUrl"
            size="medium"
            [lastName]="profile?.last_name"
            [firstName]="profile?.first_name"
            [email]="profile?.email"
            class="header__user__avatar"
          ></div>
          <div class="header__user__arrow"></div>
        </div>
        <button [routerLink]="['/', 'profile']" class="header__user d-lg-none">
          <div
            appUserAvatar
            [src]="profile?.avatar | storageUrl"
            size="medium"
            [lastName]="profile?.last_name"
            [firstName]="profile?.first_name"
            class="header__user__avatar"
          ></div>
        </button>
      </ng-container>

      <button (click)="onSignIn.emit()" *ngIf="!isAuthorized" class="d-md-none">
        <img src="/assets/images/icons/login.svg" alt="" />
      </button>
      <button class="menu-button d-lg-none" (click)="toggleMenu()">
        <img *ngIf="!isShowMenu" src="/assets/images/icons/burger/default.svg" alt="" />
        <img *ngIf="isShowMenu" src="/assets/images/icons/burger/close.svg" alt="" />
      </button>
      <ng-container *ngIf="!isAuthorized">
        <button (click)="onSignIn.emit()" class="d-none d-lg-block button button--outline">Войти</button>
        <button (click)="onSignUp.emit()" class="d-none d-lg-block button">Зарегистрироваться</button>
      </ng-container>
    </div>
  </div>
  <div class="sidenav" *ngIf="isShowMenu">
    <div class="sidenav--top">
      <div class="sidenav--menu" *ngIf="isAuthorized">
        <div class="sidenav--nav">
          <div class="sidenav--nav-item" [routerLink]="'/search'" (click)="toggleMenu()">Поиск</div>
          <div class="sidenav--nav-item" [routerLink]="'/selections'" (click)="toggleMenu()">Подборки литературы</div>
          <div class="sidenav--nav-item" [routerLink]="'/tracking'">Отслеживание статей</div>
          <div class="sidenav--nav-item" [routerLink]="'/groups'">Группы</div>
          <div class="sidenav--nav-item" [routerLink]="'/my-articles'">Мои статьи</div>
        </div>
      </div>
      <div class="sidenav--menu sidenav--menu_user" *ngIf="isAuthorized">
        <div class="sidenav--nav">
          <div class="sidenav--nav-item" (click)="toggleMenu()" [routerLink]="['/', 'profile']">Настройки профиля</div>
          <div
            class="sidenav--nav-item"
            (click)="toggleMenu()"
            [routerLink]="['/', 'profile']"
            [queryParams]="{ tab: '1' }"
          >
            Тарифы
          </div>
          <div
            class="sidenav--nav-item"
            (click)="toggleMenu()"
            [routerLink]="['/', 'profile']"
            [queryParams]="{ tab: '2' }"
          >
            Обратная связь
          </div>
          <div
            class="sidenav--nav-item"
            (click)="toggleMenu()"
            [routerLink]="['/', 'profile']"
            [queryParams]="{ tab: '3' }"
          >
            Реквизиты
          </div>
        </div>
      </div>
    </div>
    <div class="sidenav--buttons" *ngIf="!isAuthorized">
      <button (click)="onSignIn.emit()" class="button button--outline">Войти</button>
      <button (click)="onSignUp.emit()" class="button w-100">Зарегистрироваться</button>
    </div>
    <div class="sidenav--buttons" *ngIf="isAuthorized">
      <div (click)="onLogOut.emit()" class="sidenav--logout">
        <img src="/assets/images/icons/logout.svg" alt="" />
        Выйти из аккаунта
      </div>
    </div>
    <div class="sidenav--footer">
      <div class="sidenav--phones">
        <a class="sidenav--phone" href="tel:8 (495) 567-1515">8 (495) 567-1515</a>
        <a class="sidenav--phone" href="tel:8 (985) 422-0950">8 (985) 422-0950</a>
      </div>
      <app-social class="sidenav--social"></app-social>
    </div>
  </div>
</header>

<mat-menu #menu="matMenu" xPosition="before">
  <button [routerLink]="['/', 'profile']" mat-menu-item>
    <span class="item">Настройки профиля</span>
  </button>
  <button [routerLink]="['/', 'profile']" [queryParams]="{ tab: '1' }" mat-menu-item>
    <span class="item">Тарифы</span>
  </button>
  <button [routerLink]="['/', 'profile']" [queryParams]="{ tab: '2' }" mat-menu-item>
    <span class="item">Обратная связь</span>
  </button>
  <button [routerLink]="['/', 'profile']" [queryParams]="{ tab: '3' }" mat-menu-item>
    <span class="item">Реквизиты</span>
  </button>
  <div class="divider"></div>
  <button mat-menu-item (click)="onLogOut.emit()">
    <img src="/assets/images/quit.svg" />
    <span class="quit-text">Выйти из аккаунта</span>
  </button>
</mat-menu>
