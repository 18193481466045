import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'coerceNumber',
})
export class CoerceNumberPipe implements PipeTransform {
  transform(value: number): number;
  transform(value: string | number | null): number | null;
  transform(value: string | number | null): number | null {
    if (value === null || typeof value === 'undefined') {
      return null;
    }

    switch (typeof value) {
      case 'number':
        return value;
      case 'string':
        // eslint-disable-next-line no-case-declarations
        const strCleaned = value.replace(/\s/g, '');
        return !isNaN(+strCleaned) ? +strCleaned : null;
    }

    return +value;
  }
}
