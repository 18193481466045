import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IgnoreSnackbarException } from '@app/core/snack-bar/snack-bar-rxjs.utils';
import { catchError, Observable, take, tap, throwError } from 'rxjs';

import { TariffRestrictionModalComponent } from './tariff-restriction-modal/tariff-restriction-modal.component';

@Injectable({ providedIn: 'root' })
export class TariffRestrictionInterceptor implements HttpInterceptor {
  protected dialogOpened = false;

  constructor(private readonly dialog: MatDialog) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          const isTariffRestriction = error.error.error === 'tariff_restriction';

          if (isTariffRestriction) {
            this.openDialogIfNotOpened();
            return throwError(() => new IgnoreSnackbarException(error));
          }
        }

        return throwError(error);
      }),
    );
  }

  protected openDialogIfNotOpened(): void {
    if (this.dialogOpened) {
      return;
    }

    this.dialogOpened = true;

    this.dialog.closeAll();
    TariffRestrictionModalComponent.open(this.dialog)
      .afterClosed()
      .pipe(
        take(1),
        tap(() => (this.dialogOpened = false)),
      )
      .subscribe();
  }
}
