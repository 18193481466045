import { ChangeDetectionStrategy, Component, Inject, OnInit, Self } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';
import { ForgotPasswordRequestDto } from '@app/core/api/auth/dtos/forgot-password.request.dto';
import { ForgotPasswordResponseDto } from '@app/core/api/auth/dtos/forgot-password.response.dto';
import { LoaderService } from '@app/core/loader/loader.service';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';
import { snackBarApiErrorFallback } from '@app/core/snack-bar/snack-bar-rxjs.utils';
import { DestroyService } from '@app/shared/utils/destroy.service';
import { BehaviorSubject, Observable, takeUntil, tap } from 'rxjs';

export interface PasswordRecoveryModalData {
  email: string | null;
}

@Component({
  selector: 'app-password-recovery-modal',
  templateUrl: './password-recovery-modal.component.html',
  styleUrls: ['./password-recovery-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class PasswordRecoveryModalComponent implements OnInit {
  recoveryForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  emailSent$ = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: PasswordRecoveryModalData,
    private readonly authApi: AuthApiService,
    private readonly snackBar: SnackBarService,
    private readonly loaderService: LoaderService,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  static open(
    dialog: MatDialog,
    data: PasswordRecoveryModalData,
  ): MatDialogRef<PasswordRecoveryModalComponent, boolean> {
    return dialog.open(PasswordRecoveryModalComponent, {
      data,
      width: '480px',
    });
  }

  ngOnInit(): void {
    this.recoveryForm.reset({
      email: this.data.email,
    });
  }

  onSubmit(): void {
    if (this.recoveryForm.valid) {
      const value = this.recoveryForm.value;
      this.wrapForgotPasswordRequest({
        email: value.email as string,
      })
        .pipe(
          tap(() => this.emailSent$.next(true)),
          takeUntil(this.destroy$),
        )
        .subscribe();
    } else {
      this.recoveryForm.markAllAsTouched();
    }
  }

  protected wrapForgotPasswordRequest(params: ForgotPasswordRequestDto): Observable<ForgotPasswordResponseDto> {
    const source$ = this.authApi
      .forgotPassword(params)
      .pipe(snackBarApiErrorFallback(this.snackBar, 'Не удалось отправить письмо'));

    return this.loaderService.doLoading(source$, this, 'SEND_EMAIL');
  }
}
