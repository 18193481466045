import { ChangeDetectionStrategy, Component, Inject, Self } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DestroyService } from '@app/shared/utils/destroy.service';

export interface SoonModalComponentData {}

@Component({
  selector: 'app-soon-modal',
  templateUrl: './soon-modal.component.html',
  styleUrls: ['./soon-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class SoonModalComponent {
  constructor(@Self() private readonly destroy$: DestroyService) {}

  static open(dialog: MatDialog, data: SoonModalComponentData): MatDialogRef<SoonModalComponent, void> {
    return dialog.open(SoonModalComponent, {
      data,
      width: '480px',
    });
  }
}
