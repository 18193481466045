import { HttpErrorResponse } from '@angular/common/http';

import { isApiBaseError } from './is-api-base-error.helper';

import { ApiBaseError } from '../interfaces/api-base-error.interface';

export function extractApiBaseError(errorResponse: unknown): ApiBaseError | null {
  if (errorResponse instanceof HttpErrorResponse && isApiBaseError(errorResponse.error)) {
    return errorResponse.error;
  }

  return null;
}
