/**
 * Проверка задано ли свойство.
 *
 * @param value
 */
export function isDefined(value: null | undefined): false;
export function isDefined<T>(value: T | null | undefined): boolean;
export function isDefined<T>(value: T | null | undefined): boolean {
  return typeof value !== 'undefined' && value !== null;
}

/**
 * Возвращает значение по умолчанию, если свойство не задано.
 *
 * @param value
 * @param defaultValue
 */
export function definedElse<T>(value: T | null | undefined, defaultValue: T): T {
  return isDefined(value) ? (value as T) : defaultValue;
}
