import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AuthService } from './services/auth.service';
import { UnauthorizedErrorInterceptor } from './services/unauthorized-error.interceptor';
import { AuthProfileState } from './state/auth-profile.state';
import { TokenInterceptor } from './services/token.interceptor';

@NgModule({
  imports: [NgxsModule.forFeature([AuthProfileState])],
  providers: [
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => () => authService.init(),
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
