export namespace AuthProfileStateActions {
  export class Load {
    static readonly type = '[AuthProfile] Load';
  }

  export class LoadByToken {
    public readonly token: string;

    constructor(token: string) {
      this.token = token;
    }

    static readonly type = '[AuthProfile] LoadByToken';
  }

  export class Clear {
    static readonly type = '[AuthProfile] Clear';
  }
}
