import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StorageUrlPipe } from './storage-url.pipe';

@NgModule({
  declarations: [StorageUrlPipe],
  exports: [StorageUrlPipe],
  imports: [CommonModule],
})
export class StoragePipesModule {}
