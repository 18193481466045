import { ComponentType } from '@angular/cdk/overlay';
import { ApplicationRef, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { concat, first, interval } from 'rxjs';

import { UpdaterComponent } from './updater.component';

const UPDATE_CHECK_INTERVAL_MS = 30000;

@Injectable()
export class UpdaterService {
  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly appRef: ApplicationRef,
    private readonly snackBar: MatSnackBar,
  ) {
    this.swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.openUpdateBar();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });

    if (this.swUpdate.isEnabled) {
      console.log('Subscribing to updates');
      const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable));
      const updateInterval$ = interval(UPDATE_CHECK_INTERVAL_MS);
      const updateIntervalSinceAppIsStable$ = concat(appIsStable$, updateInterval$);

      updateIntervalSinceAppIsStable$.subscribe(async () => {
        try {
          await this.swUpdate.checkForUpdate();
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }
  }

  public openUpdateBar(): void {
    this.snackBar.openFromComponent(UpdaterComponent as ComponentType<UpdaterComponent>, {
      panelClass: ['snack-bar', 'snack-bar--update-message'],
      verticalPosition: 'bottom',
      horizontalPosition: 'start',
    });
  }
}
