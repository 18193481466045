export const oauthConfig: any = {
  yClientId: '8a954bd3f7084fa3abbe7c22796e89ee',
  yRedirectUri: `${window.location.protocol}//${window.location.host}/redirect/yandex`,
  yResponseType: 'code',
  yState: '123',
  gClientId: '578529046787-pqj90arpi5dpm34oomgvbd12h7ab8vd1.apps.googleusercontent.com',
  gRedirectUri: `${window.location.protocol}//${window.location.host}/redirect/google`,
  gResponseType: 'code',
  gScope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
  gState: '123',
};
