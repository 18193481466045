<div class="modal-container">
  <div class="header">Ошибка</div>
  <button class="modal-close" mat-dialog-close>
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <div class="body">
    <div class="description">
      <div>Данный функционал не входит в ваш тариф</div>
    </div>

    <div class="actions">
      <button type="button" mat-dialog-close class="button button--outline">Закрыть</button>
      <a class="button btn-primary" [routerLink]="['/', 'profile']" [queryParams]="{ tab: 1 }" mat-dialog-close>
        К тарифам
      </a>
    </div>
  </div>
</div>
