import { Pipe, PipeTransform } from '@angular/core';
import { LoaderService } from '@app/core/loader/loader.service';
import { Observable } from 'rxjs';

/**
 *
 */
@Pipe({
  name: 'isLoading',
})
export class IsLoadingPipe implements PipeTransform {
  constructor(private readonly loaderService: LoaderService) {}

  public transform(context: object, loaderId?: string): Observable<boolean> {
    return this.loaderService.isLoading$(context, loaderId);
  }
}
