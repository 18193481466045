import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LoaderModule } from '@app/core/loader/loader.module';
import { BasePipesModule } from '@app/shared/pipes/base-pipes/base-pipes.module';
import { DialogModule } from '@app/shared/ui/dialog';
import { InputTextModule } from '@app/shared/ui/forms/input-text/input-text.module';

import { PasswordRecoveryModalComponent } from './password-recovery-modal.component';

@NgModule({
  declarations: [PasswordRecoveryModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    LoaderModule,
    BasePipesModule,
    MatIconModule,
  ],
  exports: [PasswordRecoveryModalComponent],
})
export class PasswordRecoveryModalModule {}
