import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthRegisterRequestDto } from './dtos/auth-register.request.dto';
import { AuthRegisterResponseDto } from './dtos/auth-register.response.dto';
import { ForgotPasswordRequestDto } from './dtos/forgot-password.request.dto';
import { ForgotPasswordResponseDto } from './dtos/forgot-password.response.dto';
import { LoginRequestDto } from './dtos/login.request.dto';
import { LoginResponseDto } from './dtos/login.response.dto';
import { LoginOauthResponseDto } from './dtos/login-oauth.response.dto';
import { ResetPasswordRequestDto } from './dtos/reset-password.request.dto';
import { ResetPasswordResponseDto } from './dtos/reset-password.response.dto';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private readonly http: HttpClient) {}

  register(params: AuthRegisterRequestDto): Observable<AuthRegisterResponseDto> {
    return this.http.post<AuthRegisterResponseDto>('/api/v1/register', params);
  }

  login(params: LoginRequestDto): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>('/api/v1/login', params);
  }

  yandex(code: string): Observable<LoginOauthResponseDto> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('code', code);

    return this.http.get<LoginOauthResponseDto>('/api/v1/oauth/yandex', { params: queryParams });
  }

  google(code: string): Observable<LoginOauthResponseDto> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('code', code);

    return this.http.get<LoginOauthResponseDto>('/api/v1/oauth/google', { params: queryParams });
  }

  logout(): Observable<unknown> {
    return this.http.post<unknown>('/api/v1/logout', {});
  }

  forgotPassword(params: ForgotPasswordRequestDto): Observable<ForgotPasswordResponseDto> {
    return this.http.post<ForgotPasswordResponseDto>('/api/v1/forgot-password', params);
  }

  resetPassword(params: ResetPasswordRequestDto): Observable<ResetPasswordResponseDto> {
    return this.http.post<ResetPasswordResponseDto>('/api/v1/reset-password', params);
  }
}
