<div class="input-text__label-wrapper">
  <label [for]="id"><ng-content select="app-input-label, [appInputLabel]"></ng-content></label>
  <ng-content select="app-input-hint-upper, [appInputHintUpper]"></ng-content>
</div>
<div class="input-text__field">
  <div class="input-text__wrapper">
    <ng-content select="app-input-prefix, [appInputPrefix]"></ng-content>
    <div class="input-text__infix" (click)="focus()">
      <ng-container [ngSwitch]="type">
        <textarea
          #inputElement
          *ngSwitchCase="'textarea'"
          [id]="id"
          [placeholder]="placeholder | default: ''"
          [formControl]="control"
          cdkTextareaAutosize
          [cdkAutosizeMinRows]="minRows"
          [cdkAutosizeMaxRows]="maxRows"
          (focus)="onFocused()"
          (blur)="onBlur()"
        ></textarea>
        <input
          *ngSwitchDefault
          #inputElement
          [id]="id"
          [type]="type | default: 'text'"
          [placeholder]="placeholder | default: ''"
          [formControl]="control"
          (focus)="onFocused()"
          (blur)="onBlur()"
        />
      </ng-container>
    </div>
    <ng-content select="app-input-suffix, [appInputSuffix]"></ng-content>
  </div>
  <ng-content select="app-input-error, [appInputError]"></ng-content>
</div>
