import { ChangeDetectionStrategy, Component, OnInit, Self } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationStart,
  Params,
  Router,
} from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { AuthProfileState } from '@app/core/auth/state/auth-profile.state';
import { ResetPasswordModalComponent } from '@app/modals/reset-password-modal/reset-password-modal.component';
import { SignInModalComponent } from '@app/modals/sign-in-modal/sign-in-modal.component';
import { SignUpModalComponent } from '@app/modals/sign-up-modal/sign-up-modal.component';
import { DestroyService } from '@app/shared/utils/destroy.service';
import { Select } from '@ngxs/store';
import { filter, map, Observable, startWith, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-route-handler',
  templateUrl: './route-handler.component.html',
  styleUrls: ['./route-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class RouteHandlerComponent implements OnInit {
  @Select(AuthProfileState.initialized) profileInitialized$!: Observable<boolean>;

  authenticated$ = this.authService.authenticated$;

  route$: Observable<string> | null;

  constructor(
    private readonly authService: AuthService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.route$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(this.router),
      map((navigation: unknown) => (navigation as NavigationEnd).url),
      takeUntil(this.destroy$),
    );

    this.activatedRoute.queryParams
      .pipe(
        tap((query) => this.parseQueryParams(query)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  protected parseQueryParams(query: Params): void {
    if (query['resetPasswordToken']) {
      ResetPasswordModalComponent.open(this.dialog, {
        email: query['email'],
        token: query['resetPasswordToken'],
      })
        .afterClosed()
        .pipe(
          tap(() =>
            this.router.navigate([], {
              queryParams: {},
              relativeTo: this.activatedRoute,
            }),
          ),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  logout(): void {
    this.authService
      .logout()
      .pipe(
        take(1),
        tap(() => {
          this.clearLocalStorage();
          this.router.navigate(['/']);
        }),
      )
      .subscribe();
  }

  clearLocalStorage(): void {
    localStorage.removeItem('lastSelectionId');
  }

  openSignInModal(): void {
    SignInModalComponent.open(this.dialog, {});
  }

  openSignUpModal(): void {
    SignUpModalComponent.open(this.dialog, {});
  }
}
