import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LoaderModule } from '@app/core/loader/loader.module';
import { LetModule } from '@app/shared/directives/let';
import { BasePipesModule } from '@app/shared/pipes/base-pipes/base-pipes.module';
import { DialogModule } from '@app/shared/ui/dialog';
import { InputSharedModule } from '@app/shared/ui/forms/input-shared/input-shared.module';
import { InputTextModule } from '@app/shared/ui/forms/input-text/input-text.module';

import { ResetPasswordModalComponent } from './reset-password-modal.component';

@NgModule({
  declarations: [ResetPasswordModalComponent],
  imports: [
    CommonModule,
    DialogModule,
    BasePipesModule,
    InputSharedModule,
    InputTextModule,
    LetModule,
    LoaderModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  exports: [ResetPasswordModalComponent],
})
export class ResetPasswordModalModule {}
