<div class="modal-container" [appLoader]="this | isLoading | async | default: false">
    <div class="modal-content">
        <div class="header">Раздел находится в разработке</div>
        <div class="paragraph">
            Уже скоро вы сможете попробовать все возможности сервиса
        </div>
        <button class="modal-close" mat-dialog-close>
            <mat-icon svgIcon="close"></mat-icon>
        </button>
        <div class="registration-complete__actions">
            <button class="button" type="button" mat-dialog-close="">Хорошо</button>
        </div>
    </div>
</div>
