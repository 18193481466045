import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BasePipesModule } from '@app/shared/pipes/base-pipes/base-pipes.module';

import { UserAvatarComponent } from './user-avatar.component';

@NgModule({
  declarations: [UserAvatarComponent],
  imports: [CommonModule, BasePipesModule, MatIconModule],
  exports: [UserAvatarComponent],
})
export class UserAvatarModule {}
