import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { UpdaterComponent } from './updater.component';
import { UpdaterService } from './updater.service';

@NgModule({
  declarations: [UpdaterComponent],
  imports: [CommonModule, MatSnackBarModule],
  providers: [
    UpdaterService,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [UpdaterService],
      multi: true,
    },
  ],
})
export class UpdaterModule {}
