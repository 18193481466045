import { Directive } from '@angular/core';

@Directive({
  selector: 'app-input-hint-upper, [appInputHintUpper]',
  exportAs: 'appInputHintUpper',
  host: {
    class: 'input-hint input-hint-upper',
  },
})
export class InputHintUpperDirective {}
