import { Observable } from 'rxjs';

import { escapeRegExp } from './regex.utils';

export function readFile(file: File | Blob): Observable<string> {
  return new Observable<string>((subscriber) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      subscriber.next(e.target?.result as string);
      subscriber.complete();
    };

    reader.onerror = (e) => {
      subscriber.error(e.target?.error);
    };
  });
}

export function validateAccept(file: File, accept: string | string[] | null): boolean {
  if (!accept || !file?.type) {
    return true;
  }

  const acceptArray = Array.isArray(accept) ? accept : accept.split(',').map((item) => item.trim());
  const acceptRegexStr = acceptArray
    .map((item) => `^${escapeRegExp(item, ['*'])}$`)
    .join('|')
    .replace(/\*/g, '.*');

  const regex = new RegExp(acceptRegexStr);
  return regex.test(file.type);
}
