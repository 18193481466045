import { Dialog as CdkDialog, DialogModule as CdkDialogModule } from '@angular/cdk/dialog';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';

import { DialogService } from './dialog.service';
import { DIALOG_DEFAULT_OPTIONS } from './dialog-default-options.const';

@NgModule({
  imports: [MatDialogModule, CdkDialogModule],
  providers: [
    DialogService,
    {
      provide: CdkDialog,
      useExisting: DialogService,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: DIALOG_DEFAULT_OPTIONS,
    },
  ],
  exports: [MatDialogModule],
})
export class DialogModule {}
