import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const SVG_ICONS: string[] = [
  'clean-input',
  'eye',
  'eye-closed',
  'warn-triangle',
  'check',
  'pencil',
  'camera',
  'close',
  'question',
  'pencil-underline',
  'export',
  'user-add',
  'archive',
  'more-vert',
  'arrow-turn',
  'trash',
  'breadcrumbs-arrow',
  'arrow-right',
  'collapse',
  'add-selection',
  'add-tracking',
  'broom',
  'bookmark',
  'bookmark-filled',
  'plus',
  'play',
  'stop',
  'checkbox-plus',
  'checkbox-minus',
  'crown',
  'user',
  'copy-plus',
  'pencil-squared',
  'note',
  'chevron',
  'history',
  'calendar',
];

@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [MatIconModule],
})
export class SvgIconModule {
  constructor(registry: MatIconRegistry, ds: DomSanitizer) {
    SVG_ICONS.forEach((icon) =>
      registry.addSvgIcon(icon, ds.bypassSecurityTrustResourceUrl(`/assets/images/icons/${icon}.svg`)),
    );
  }
}
