import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

export type UserAvatarSize = 'small' | 'medium';

@Component({
  selector: 'app-user-avatar, div[appUserAvatar]',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'user-avatar',
  },
})
export class UserAvatarComponent {
  @Input() src?: string | ArrayBuffer | null;

  @Input() firstName?: string | null;

  @Input() lastName?: string | null;

  @Input() email?: string | null;

  @Input() size: UserAvatarSize = 'medium';

  @HostBinding('class')
  get classes(): string[] {
    return [`_${this.size}`];
  }

  @HostBinding('title')
  get title(): string {
    if (this.firstName || this.lastName) {
      return [this.firstName, this.lastName].filter(Boolean).join(' ');
    }

    return this.email || '';
  }
}
