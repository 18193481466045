import { NgModule } from '@angular/core';
import {SocialComponent} from "../social/social.component";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [SocialComponent],
  exports: [SocialComponent],
  imports: [
    CommonModule
  ],
})
export class SocialModule {}
