/* eslint-disable @typescript-eslint/dot-notation */
import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { LoaderSpinnerComponent } from '@app/core/loader/loader-spinner/loader-spinner.component';

@Directive({
  selector: '[appLoader]',
})
export class LoaderDirective implements OnChanges {
  @Input('appLoader')
  isLoading = false;

  protected overlayElement!: HTMLDivElement;

  protected spinnerElement!: HTMLDivElement;

  protected hostElement!: HTMLDivElement;

  protected spinnerComponentRef?: ComponentRef<unknown>;

  constructor(
    protected readonly elementRef: ElementRef,
    protected readonly renderer: Renderer2,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly viewContainerRef: ViewContainerRef,
  ) {
    this.hostElement = this.elementRef.nativeElement;
    this.hostElement.style.position = 'relative';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isLoading']) {
      const isLoadingValue = changes['isLoading'].currentValue;

      if (isLoadingValue) {
        this.addLoadingIndicator();
      } else {
        this.removeLoadingIndicator();
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  protected addLoadingIndicator(): void {
    if (this.spinnerComponentRef) {
      this.spinnerComponentRef.destroy();
    }

    this.spinnerComponentRef = this.viewContainerRef.createComponent(LoaderSpinnerComponent);
  }

  protected removeLoadingIndicator(): void {
    if (this.spinnerComponentRef) {
      this.spinnerComponentRef.destroy();
    }

    this.viewContainerRef.clear();
  }
}
