import { Injectable } from '@angular/core';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';
import { AuthRegisterRequestDto } from '@app/core/api/auth/dtos/auth-register.request.dto';
import { AuthRegisterResponseDto } from '@app/core/api/auth/dtos/auth-register.response.dto';
import { LoginRequestDto } from '@app/core/api/auth/dtos/login.request.dto';
import { LoginResponseDto } from '@app/core/api/auth/dtos/login.response.dto';
import { LoginOauthResponseDto } from '@app/core/api/auth/dtos/login-oauth.response.dto';
import { UserUpdateProfileRequestDto } from '@app/core/api/user/dtos/user-update-profile.request.dto';
import { UserUpdateProfileResponseDto } from '@app/core/api/user/dtos/user-update-profile.response.dto';
import { UserApiService } from '@app/core/api/user/user-api.service';
import { Store } from '@ngxs/store';
import { filter, map, Observable, switchMap, take, tap } from 'rxjs';

import { ProfileModel } from '../models/profile.model';
import { AuthProfileState } from '../state/auth-profile.state';
import { AuthProfileStateActions } from '../state/auth-profile.state.actions';

@Injectable({ providedIn: 'root' })
export class AuthService {
  profile$: Observable<ProfileModel | null>;

  authenticated$: Observable<boolean>;

  initialized = false;

  constructor(
    private readonly store: Store,
    private readonly authApi: AuthApiService,
    private readonly userApi: UserApiService,
  ) {
    this.profile$ = this.store.select(AuthProfileState.initialized).pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.store.select(AuthProfileState.profile)),
    );

    this.authenticated$ = this.profile$.pipe(map(Boolean));
  }

  init(): void {
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    this.store.dispatch(new AuthProfileStateActions.Load());
  }

  login(params: LoginRequestDto): Observable<LoginResponseDto> {
    return this.authApi.login(params).pipe(tap(() => this.store.dispatch(new AuthProfileStateActions.Load())));
  }

  yandex(code: string): Observable<LoginOauthResponseDto> {
    return this.authApi
      .yandex(code)
      .pipe(
        tap((responce: LoginOauthResponseDto) =>
          this.store.dispatch(new AuthProfileStateActions.LoadByToken(responce.data)),
        ),
      );
  }

  google(code: string): Observable<LoginOauthResponseDto> {
    return this.authApi
      .google(code)
      .pipe(
        tap((response: LoginOauthResponseDto) =>
          this.store.dispatch(new AuthProfileStateActions.LoadByToken(response.data)),
        ),
      );
  }

  signUp(params: AuthRegisterRequestDto): Observable<AuthRegisterResponseDto> {
    return this.authApi.register(params).pipe(tap(() => this.store.dispatch(new AuthProfileStateActions.Load())));
  }

  logout(): Observable<unknown> {
    return this.authApi.logout().pipe(tap(() => this.store.dispatch(new AuthProfileStateActions.Clear())));
  }

  updateProfile(params: UserUpdateProfileRequestDto): Observable<UserUpdateProfileResponseDto> {
    return this.userApi.updateProfile(params).pipe(tap(() => this.store.dispatch(new AuthProfileStateActions.Load())));
  }

  deleteAvatar(): Observable<unknown> {
    return this.userApi.deleteAvatar().pipe(tap(() => this.store.dispatch(new AuthProfileStateActions.Load())));
  }

  // updateAvatar(params: UserUpdateAvatarRequestDto): Observable<UserUpdateProfileResponseDto> {
  //   return this.userApi.updateAvatar(params).pipe(tap(() => this.store.dispatch(new AuthProfileStateActions.Load())));
  // }
}
