import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfileModel } from '@app/core/auth/models/profile.model';
import { AuthProfileState } from '@app/core/auth/state/auth-profile.state';
import { SoonModalComponent } from '@app/modals/soon-modal/soon-modal.component';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() isAuthorized = false;

  @Output() onSignIn = new EventEmitter();

  @Output() onSignUp = new EventEmitter();

  @Output() onLogOut = new EventEmitter();

  isShowMenu = false;

  @Select(AuthProfileState.profile) profile$: Observable<ProfileModel | null>;

  constructor(private readonly dialog: MatDialog) {}

  toggleMenu(): void {
    this.isShowMenu = !this.isShowMenu;

    if (!this.isShowMenu) document.body.classList.remove('sidenav-opened');
    else document.body.classList.add('sidenav-opened');
  }

  openSoonModal(): void {
    SoonModalComponent.open(this.dialog, {});
  }
}
