import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuth();
  }

  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAuth();
  }

  protected checkAuth(): Observable<boolean> {
    return this.authService.authenticated$.pipe(
      map((authenticated) => {
        if (!authenticated) {
          this.router.navigate(['/']);
          return false;
        }

        return authenticated;
      }),
    );
  }
}
