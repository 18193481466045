import { Pipe, PipeTransform } from '@angular/core';
import { definedElse } from '@app/shared/utils';

@Pipe({
  name: 'default',
})
export class DefaultPipe implements PipeTransform {
  transform<T>(value: T | null | undefined, defaultValue: T): T {
    return definedElse(value, defaultValue);
  }
}
