import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LoaderModule } from '@app/core/loader/loader.module';
import { LetModule } from '@app/shared/directives/let';
import { BasePipesModule } from '@app/shared/pipes/base-pipes/base-pipes.module';
import { DialogModule } from '@app/shared/ui/dialog';
import { InputTextModule } from '@app/shared/ui/forms/input-text/input-text.module';

import { SignUpModalComponent } from './sign-up-modal.component';

@NgModule({
  declarations: [SignUpModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    MatIconModule,
    LetModule,
    LoaderModule,
    BasePipesModule,
  ],
})
export class SignUpModalModule {}
