import { ChangeDetectionStrategy, Component, Inject, Self } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';
import { ResetPasswordRequestDto } from '@app/core/api/auth/dtos/reset-password.request.dto';
import { ResetPasswordResponseDto } from '@app/core/api/auth/dtos/reset-password.response.dto';
import { LoaderService } from '@app/core/loader/loader.service';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';
import { snackBarApiErrorFallback } from '@app/core/snack-bar/snack-bar-rxjs.utils';
import { DestroyService } from '@app/shared/utils/destroy.service';
import { repeatPasswordGroupValidator } from '@app/shared/validators/repeat-password-group.validator';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';

export interface ResetPasswordModalComponentData {
  email: string;
  token: string;
}

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ResetPasswordModalComponent {
  resetPasswordForm = new FormGroup(
    {
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      passwordRepeat: new FormControl('', [Validators.required]),
    },
    {
      validators: [repeatPasswordGroupValidator('password', 'passwordRepeat')],
    },
  );

  showPassword$ = new BehaviorSubject<boolean>(false);

  showPasswordRepeat$ = new BehaviorSubject<boolean>(false);

  completed$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: ResetPasswordModalComponentData,
    private readonly authApi: AuthApiService,
    private readonly snackBar: SnackBarService,
    private readonly loaderService: LoaderService,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  static open(
    dialog: MatDialog,
    data: ResetPasswordModalComponentData,
  ): MatDialogRef<ResetPasswordModalComponent, void> {
    return dialog.open(ResetPasswordModalComponent, {
      data,
      width: '480px',
    });
  }

  onSubmit(): void {
    if (this.resetPasswordForm.valid) {
      const value = this.resetPasswordForm.value;

      this.wrapResetPasswordRequest({
        email: this.data.email,
        token: this.data.token,
        password: value.password!,
        password_confirmation: value.passwordRepeat!,
      })
        .pipe(tap(() => this.completed$.next(true)))
        .subscribe();
    } else {
      this.resetPasswordForm.markAllAsTouched();
    }
  }

  toggleShowPassword(): void {
    this.showPassword$.next(!this.showPassword$.value);
  }

  toggleShowPasswordRepeat(): void {
    this.showPasswordRepeat$.next(!this.showPasswordRepeat$.value);
  }

  protected wrapResetPasswordRequest(params: ResetPasswordRequestDto): Observable<ResetPasswordResponseDto> {
    const source$ = this.authApi
      .resetPassword(params)
      .pipe(take(1), snackBarApiErrorFallback(this.snackBar, 'Ошибка при смене пароля'));

    return this.loaderService.doLoading(source$, this, 'RESET_PASSWORD');
  }
}
