import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormOnChangeType } from '@app/shared/ui/forms/types/form-on-change.type';
import { FormOnTouchedType } from '@app/shared/ui/forms/types/form-on-touched.type';
import { DestroyService } from '@app/shared/utils/destroy.service';
import { takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-input-text, div[app-input-text]',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'input-text',
  },
  providers: [
    DestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTextComponent,
      multi: true,
    },
  ],
})
export class InputTextComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder?: string | null;

  /**
   * Тип инпута:
   * * 'textarea'
   * * Любое значение атрибута type <input />
   */
  @Input() type?: string | null;

  @Input() id: string = InputTextComponent.getId();

  @Input() set horizontal(value: boolean | string | undefined | null) {
    this.horizontalClass = coerceBooleanProperty(value);
  }

  /**
   * Минимальное кол-во строк для textarea
   */
  @Input() minRows: number = 1;

  /**
   * Максимальное кол-во строк для textarea
   */
  @Input() maxRows: number = Infinity;

  @HostBinding('class.input-text--disabled') disabled?: boolean | null;

  @HostBinding('class.input-text--horizontal') horizontalClass = false;

  @HostBinding('class.input-text--focused') focused = false;

  @HostBinding('class.input-text--has-value') get hasValue(): boolean {
    return !!this.control.value;
  }

  @ViewChild('inputElement') inputElementRef?: ElementRef<HTMLInputElement | HTMLTextAreaElement>;

  control = new FormControl<string | null>(null);

  onChange: FormOnChangeType = () => {};

  onTouched: FormOnTouchedType = () => {};

  static inputCounter = 0;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  static getId(): string {
    return `input-text-${InputTextComponent.inputCounter++}`;
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        tap((value) => this.onChange(value)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  registerOnChange(fn: FormOnChangeType): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: FormOnTouchedType): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({
        emitEvent: false,
      });
    } else {
      this.control.enable({
        emitEvent: false,
      });
    }

    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  writeValue(obj: string | null): void {
    this.control.reset(obj, {
      emitEvent: false,
    });
  }

  focus(): void {
    const input = this.inputElementRef?.nativeElement;

    if (input) {
      input.focus();
    }
  }

  onFocused(): void {
    this.focused = true;
  }

  onBlur(): void {
    this.focused = false;
    this.onTouched();
  }
}
