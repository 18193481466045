import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  public showInfoMessage(message: string, duration = 4000): void {
    this.snackBar.open(message, '', {
      panelClass: ['snack-bar', 'snack-bar--info'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }

  public showSuccessMessage(message: string, duration = 4000): void {
    this.snackBar.open(message, '', {
      panelClass: ['snack-bar', 'snack-bar--success'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }

  public showWarningMessage(message: string, duration = 5000): void {
    this.snackBar.open(message, '', {
      panelClass: ['snack-bar', 'snack-bar--warning'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }

  public showDangerMessage(message: string, duration = 5000): void {
    this.snackBar.open(message, '', {
      panelClass: ['snack-bar', 'snack-bar--danger'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }
}
