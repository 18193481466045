import { ChangeDetectionStrategy, Component, OnInit, Self } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginRequestDto } from '@app/core/api/auth/dtos/login.request.dto';
import { LoginResponseDto } from '@app/core/api/auth/dtos/login.response.dto';
import { AuthService } from '@app/core/auth/services/auth.service';
import { LoaderService } from '@app/core/loader/loader.service';
import { SnackBarService } from '@app/core/snack-bar/snack-bar.service';
import { snackBarApiErrorFallback } from '@app/core/snack-bar/snack-bar-rxjs.utils';
import { PasswordRecoveryModalComponent } from '@app/modals/password-recovery-modal/password-recovery-modal.component';
import { SignUpModalComponent } from '@app/modals/sign-up-modal/sign-up-modal.component';
import { DestroyService } from '@app/shared/utils/destroy.service';
import { BehaviorSubject, Observable, take, takeUntil } from 'rxjs';

import { oauthConfig } from './oauth.config';

export interface SignInModalData {}

@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class SignInModalComponent implements OnInit {
  signInForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  passwordShowed$ = new BehaviorSubject(false);

  constructor(
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<SignInModalComponent, void>,
    private readonly snackBar: SnackBarService,
    private readonly loaderService: LoaderService,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {}

  onYandex(): void {
    location.href = `https://oauth.yandex.ru/authorize?client_id=${oauthConfig.yClientId}&redirect_uri=${oauthConfig.yRedirectUri}&response_type=${oauthConfig.yResponseType}&state=${oauthConfig.yState}`;
  }

  onGoogle(): void {
    location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${oauthConfig.gClientId}&redirect_uri=${oauthConfig.gRedirectUri}&response_type=${oauthConfig.gResponseType}&state=${oauthConfig.gState}&scope=${oauthConfig.gScope}`;
  }

  static open(dialog: MatDialog, data: SignInModalData): MatDialogRef<SignInModalComponent, void> {
    return dialog.open(SignInModalComponent, {
      data,
      width: '480px',
    });
  }

  onSubmit(): void {
    if (this.signInForm.valid) {
      const value = this.signInForm.value;

      this.wrapLoginRequest({
        email: value.email as string,
        password: value.password as string,
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.dialogRef.close());
    } else {
      this.signInForm.markAllAsTouched();
    }
  }

  togglePasswordShowed(): void {
    this.passwordShowed$.next(!this.passwordShowed$.value);
  }

  switchToSignUpModal(): void {
    SignUpModalComponent.open(this.dialog, {});
    this.dialogRef.close();
  }

  switchToPasswordRecoveryModal(): void {
    PasswordRecoveryModalComponent.open(this.dialog, {
      email: this.signInForm.value.email || null,
    });
    this.dialogRef.close();
  }

  protected wrapLoginRequest(params: LoginRequestDto): Observable<LoginResponseDto> {
    const source$ = this.authService
      .login(params)
      .pipe(take(1), snackBarApiErrorFallback(this.snackBar, 'Ошибка при входе в аккаунт'));

    return this.loaderService.doLoading(source$, this, 'LOGIN');
  }
}
