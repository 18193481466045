<div class="modal-container" [appLoader]="this | isLoading | async | default: false">
  <div class="header">Вход в аккаунт</div>
  <button class="modal-close" mat-dialog-close>
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <form class="body" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
    <div class="controls">
      <app-input-text formControlName="email">
        <app-input-label>Email</app-input-label>
      </app-input-text>

      <app-input-text
        *appLet="passwordShowed$ | async as show"
        [type]="show ? 'text' : 'password'"
        formControlName="password"
      >
        <app-input-label>Пароль</app-input-label>
        <button type="button" tabindex="-1" appInputSuffix (click)="togglePasswordShowed()">
          <mat-icon [svgIcon]="show ? 'eye' : 'eye-closed'"></mat-icon>
        </button>
      </app-input-text>

      <div class="forget">
        <a class="forget__link" (click)="switchToPasswordRecoveryModal()">Забыли пароль?</a>
      </div>

      <div class="actions">
        <button type="submit" class="button" [disabled]="!signInForm.valid">Войти</button>
        <button type="button" class="button button--link" (click)="switchToSignUpModal()">Зарегистрироваться</button>
      </div>
    </div>

    <div class="alternative">
      <div>Выполнить вход через</div>
      <div class="alternative__buttons">
        <button (click)="onYandex()" type="button" class="button button--outline button--white button--small">
          <img src="assets/images/ya.png" />
          <span>Яндекс</span>
        </button>
        <button (click)="onGoogle()" type="button" class="button button--outline button--white button--small">
          <img src="assets/images/goo.png" />
          <span>Google</span>
        </button>
      </div>
    </div>
  </form>
</div>
