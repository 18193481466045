import { CommonModule } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { AuthModule } from '@app/core/auth/auth.module';
import { SettingsStoreModule } from '@app/features/settings/store/settings-store.module';
import { TariffRestrictionModule } from '@app/features/tariff/tariff-restriction/tariff-restriction.module';

import { ApiModule } from './api/api.module';
import { LoaderModule } from './loader/loader.module';
import { MediaObserverModule } from './media-observer/media-observer.module';
import { RouteHandlerModule } from './route-handler/route-handler.module';
import { SnackBarModule } from './snack-bar/snack-bar.module';
import { SvgIconModule } from './svg-icon/svg-icon.module';
import { UpdaterModule } from './updater/updater.module';

const DIALOG_DEFAULT_OPTIONS: MatDialogConfig = {};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SvgIconModule,
    ApiModule,
    SnackBarModule,
    UpdaterModule,
    LoaderModule,
    RouteHandlerModule,
    AuthModule,
    SettingsStoreModule,
    MediaObserverModule.forRoot({
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
      xxxl: 1600,
    }),
    TariffRestrictionModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'ru',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'RUB',
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: DIALOG_DEFAULT_OPTIONS,
    },
  ],
  exports: [RouteHandlerModule],
})
export class CoreModule {}
