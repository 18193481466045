import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconModule } from '@app/core/svg-icon/svg-icon.module';

import { InputErrorComponent } from './input-error/input-error.component';
import { InputHintUpperDirective } from './input-hint-upper.directive';
import { InputLabelDirective } from './input-label.directive';
import { InputPrefixDirective } from './input-prefix.directive';
import { InputSuffixDirective } from './input-suffix.directive';

@NgModule({
  declarations: [
    InputLabelDirective,
    InputSuffixDirective,
    InputPrefixDirective,
    InputErrorComponent,
    InputHintUpperDirective,
  ],
  imports: [CommonModule, SvgIconModule],
  exports: [
    InputLabelDirective,
    InputSuffixDirective,
    InputPrefixDirective,
    InputErrorComponent,
    InputHintUpperDirective,
  ],
})
export class InputSharedModule {}
