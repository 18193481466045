import { Directive } from '@angular/core';

@Directive({
  selector: 'app-input-label, [appInputLabel]',
  exportAs: 'appInputLabel',
  host: {
    class: 'input-label',
  },
})
export class InputLabelDirective {}
