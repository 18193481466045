import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

/**
 * Использование отдельного overlay контейнера для модалок, чтобы была возможность стилизовать независимо от других элементов (дропдауны, тултипы и т.д.)
 */
@Injectable({ providedIn: 'root' })
export class DialogOverlayContainerService extends OverlayContainer {
  protected override _createContainer(): void {
    super._createContainer();
    this._containerElement.classList.add('dialog-overlay-container');
  }
}
