import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoaderModule } from '@app/core/loader/loader.module';
import { ResetPasswordModalModule } from '@app/modals/reset-password-modal/reset-password-modal.module';
import { SignInModalModule } from '@app/modals/sign-in-modal/sign-in-modal.module';
import { SignUpModalModule } from '@app/modals/sign-up-modal/sign-up-modal.module';
import { BasePipesModule } from '@app/shared/pipes/base-pipes/base-pipes.module';

import { HeaderModule } from '../../components/header/header.module';
import { RouteHandlerComponent } from './route-handler.component';

@NgModule({
  declarations: [RouteHandlerComponent],
  imports: [
    CommonModule,
    RouterOutlet,
    LoaderModule,
    ResetPasswordModalModule,
    BasePipesModule,
    HeaderModule,
    SignInModalModule,
    SignUpModalModule,
  ],
  exports: [RouteHandlerComponent],
})
export class RouteHandlerModule {}
