export type IsEmptyFunction<T extends object> = (value: T[keyof T]) => boolean;
export type OmitEmptyProperties<T extends object> = Partial<{ [K in keyof T]: NonNullable<T[K]> }>;

/**
 * Убрать из объекта null и undefined свойства.
 *
 * @param value
 */
export function omitEmpty<T extends object>(value: T): OmitEmptyProperties<T> {
  if (!value) {
    return {};
  }

  const result: OmitEmptyProperties<T> = {};

  for (const key in value) {
    const prop = value[key];

    if (typeof prop === 'undefined' || prop === null) {
      continue;
    }

    result[key] = prop;
  }

  return result;
}

/**
 * Убрать из объекта свойства по критерию isEmptyFn.
 *
 * @param value
 * @param isEmptyFn
 */
export function omitEmptyBy<T extends object>(value: T, isEmptyFn: IsEmptyFunction<T> = (val) => !val): Partial<T> {
  if (!value) {
    return {};
  }

  const result: Partial<T> = {};

  for (const key in value) {
    if (isEmptyFn(value[key])) {
      continue;
    }

    result[key] = value[key];
  }

  return result;
}
