<div class="modal-container" [appLoader]="this | isLoading | async | default: false">
  <ng-container *ngIf="(completed$ | async) === false; else completedBlock">
    <div class="header">Смена пароля</div>
    <button class="modal-close" mat-dialog-close>
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <form class="body" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
      <div class="description">Придумайте новый пароль</div>
      <div class="controls">
        <app-input-text
          *appLet="showPassword$ | async as show"
          [type]="show ? 'text' : 'password'"
          placeholder="Введите пароль"
          formControlName="password"
        >
          <app-input-label>Пароль *</app-input-label>
          <button appInputSuffix type="button" tabindex="-1" (click)="toggleShowPassword()">
            <mat-icon [svgIcon]="show ? 'eye' : 'eye-closed'"></mat-icon>
          </button>
          <app-input-error
            *ngIf="
              resetPasswordForm.get('password')?.touched &&
              resetPasswordForm.get('password')?.getError('minlength') as minLengthError
            "
          >
            Пароль должен содержать минимум {{ minLengthError.requiredLength }} символов
          </app-input-error>
        </app-input-text>

        <app-input-text
          *appLet="showPasswordRepeat$ | async as show"
          [type]="show ? 'text' : 'password'"
          placeholder="Введите пароль"
          formControlName="passwordRepeat"
        >
          <app-input-label>Повторите пароль *</app-input-label>
          <button appInputSuffix type="button" tabindex="-1" (click)="toggleShowPasswordRepeat()">
            <mat-icon [svgIcon]="show ? 'eye' : 'eye-closed'"></mat-icon>
          </button>
          <app-input-error
            *ngIf="
              resetPasswordForm.get('passwordRepeat')?.touched && resetPasswordForm.hasError('repeatPasswordError')
            "
          >
            Пароли должны совпадать
          </app-input-error>
        </app-input-text>
      </div>

      <div class="actions">
        <button type="submit" class="button btn-primary" [disabled]="resetPasswordForm.invalid">Сохранить</button>
      </div>
    </form>
  </ng-container>
  <ng-template #completedBlock>
    <div class="registration-complete">
      <div class="registration-complete__icon">
        <mat-icon svgIcon="check"></mat-icon>
      </div>
      <div class="registration-complete__title">Пароль успешно обновлён</div>
      <div class="registration-complete__actions">
        <button class="button" type="button" mat-dialog-close="">Закрыть</button>
      </div>
    </div>
  </ng-template>
</div>
