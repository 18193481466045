<div class="social-links">
  <a href="#">
    <img src="/assets/images/icons/social-tg.svg" alt="" />
  </a>
  <a href="#">
    <img src="/assets/images/icons/social-phone.svg" alt="" />
  </a>
  <a href="#">
    <img src="/assets/images/icons/social-vk.svg" alt="" />
  </a>
  <a href="#">
    <img src="/assets/images/icons/social-whatsapp.svg" alt="" />
  </a>
</div>
