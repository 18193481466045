import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseResponseDto } from '@app/core/api/api-base-response.dto';
import { UserResponseDto } from '@app/core/api/user/dtos/user.response.dto';
import { UserUpdateProfileRequestDto } from '@app/core/api/user/dtos/user-update-profile.request.dto';
import { UserUpdateProfileResponseDto } from '@app/core/api/user/dtos/user-update-profile.response.dto';
import { UsersSearchRequestDto } from '@app/core/api/user/dtos/users-search.request.dto';
import { omitEmpty } from '@app/shared/utils';
import { map, Observable } from 'rxjs';

import { DataResponseDto } from './dtos/data.response.dto';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(private readonly http: HttpClient) {}

  me(): Observable<DataResponseDto | null> {
    return this.http.get<DataResponseDto>('/api/v1/user');
  }

  updateProfile(params: UserUpdateProfileRequestDto): Observable<UserUpdateProfileResponseDto> {
    const formData = new FormData();

    for (const key in params) {
      formData.set(key, (<Record<string, string | Blob>>params)[key]);
    }

    formData.set('_method', 'put');

    return this.http.post<UserUpdateProfileResponseDto>('/api/v1/user/profile-information', formData);
    // return this.http.put<UserUpdateProfileResponseDto>('/api/v1/user/profile-information', params);
  }

  // updateAvatar(params: UserUpdateAvatarRequestDto): Observable<UserUpdateProfileResponseDto> {
  //   const formData = new FormData();

  //   for (const key in params) {
  //     formData.set(key, (<Record<string, string | Blob>>params)[key]);
  //   }

  //   formData.set('_method', 'put');

  //   return this.http.post<UserUpdateProfileResponseDto>('/api/v1/user/profile-information', formData);
  // }

  deleteAvatar(): Observable<unknown> {
    return this.http.delete<unknown>('/api/v1/user/avatar');
  }

  searchUsers(request: UsersSearchRequestDto): Observable<UserResponseDto[]> {
    let queryParams = new HttpParams();

    queryParams = queryParams.appendAll(
      omitEmpty({
        query: request.query,
        limit: request.limit,
      }),
    );

    return this.http
      .get<ApiBaseResponseDto<UserResponseDto[]>>('/api/v1/users/search', {
        params: queryParams,
      })
      .pipe(map((response) => response.data));
  }
}
