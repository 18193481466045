import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoerceNumberPipe } from './coerce-number.pipe';
import { DefaultPipe } from './default.pipe';

@NgModule({
  declarations: [DefaultPipe, CoerceNumberPipe],
  exports: [DefaultPipe, CoerceNumberPipe],
  imports: [CommonModule],
})
export class BasePipesModule {}
