import { Injectable } from '@angular/core';
import {
  SettingsCustomArticleLanguageDto,
  SettingsDto,
  SettingSearchSourcesDto,
  SettingsItemDto,
  SettingsLanguageDto,
} from '@app/core/api/settings/dtos/settings.response.dto';
import { SettingsApiService } from '@app/core/api/settings/settings-api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, EMPTY, map, Observable, tap } from 'rxjs';

import { SettingsStateActions } from './settings.state.actions';

type SettingsStateModel = SettingsDto;

const EMPTY_STATE: SettingsStateModel = {
  custom_articles_languages: {},
  languages: [],
  positions: [],
  search_sources: {},
  specialities: [],
};

@State<SettingsStateModel>({
  name: 'settings',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class SettingsState {
  @Selector()
  public static languages(state: SettingsStateModel): SettingsLanguageDto[] {
    return state.languages;
  }

  @Selector()
  public static customArticlesLanguages(state: SettingsStateModel): SettingsCustomArticleLanguageDto {
    return state.custom_articles_languages;
  }

  @Selector()
  public static positions(state: SettingsStateModel): SettingsItemDto[] {
    return state.positions;
  }

  @Selector()
  public static specialities(state: SettingsStateModel): SettingsItemDto[] {
    return state.specialities;
  }

  @Selector()
  public static searchSources(state: SettingsStateModel): SettingSearchSourcesDto {
    return state.search_sources;
  }

  constructor(private readonly settingsApi: SettingsApiService) {}

  @Action(SettingsStateActions.Load)
  public load({ setState }: StateContext<SettingsStateModel>): Observable<SettingsDto> {
    return this.settingsApi.get().pipe(
      map((response) => response.data),
      tap((settings) => setState(settings)),
      catchError(() => EMPTY),
    );
  }
}
